/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Istanbul"), React.createElement("p", null, "Pour se loger à Istanbul, il faut d’abord repérer le Bosphore, qui sépare ", React.createElement("a", {
    href: "https://www.booking.com/district/tr/istanbul/european-side.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "l’Europe"), " et ", React.createElement("a", {
    href: "https://www.booking.com/district/tr/istanbul/asian-side.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "l’Asie"), ". On peut ensuite distinguer la ", React.createElement("a", {
    href: "https://www.booking.com/district/tr/istanbul/goldenhorn.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Corne d’Or"), ", sorte d’estuaire qui divise la rive européenne en deux parties."), React.createElement("p", null, "On aboutit ainsi à quatre grandes zones :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "La ville historique au sud, autour de ", React.createElement("a", {
    href: "https://www.booking.com/district/tr/istanbul/oldcitysultanahmet.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Sultanahmet"), ", qui concentre les attractions touristiques."), "\n", React.createElement(_components.li, null, "Le centre-ville actuel au nord, autour de Beyoğlu, avec l’avenue Istiklal qui remonte jusqu’à la ", React.createElement("a", {
    href: "https://www.booking.com/district/tr/istanbul/taksimsquare.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "place Taksim"), "."), "\n", React.createElement(_components.li, null, "Le quartier des affaires, sur les hauteurs de ", React.createElement("a", {
    href: "https://www.booking.com/district/tr/istanbul/besiktas.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Besiktas"), ", encore plus au nord."), "\n", React.createElement(_components.li, null, "La rive anatolienne enfin, avec les quartiers d’", React.createElement("a", {
    href: "https://www.booking.com/district/tr/istanbul/uskudar.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Üsküdar"), " et ", React.createElement("a", {
    href: "https://www.booking.com/district/tr/istanbul/asiansidekadikoy.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Kadiköy"), "."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
